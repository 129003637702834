.beauty-modal .modal-content {
  max-width: none;
  border-radius: 10px;
}

.beauty-modal .modal-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  padding: 8px 0;
}

.beauty-modal .modal-header h5 {
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
}

/* .beauty-modal .modal-content .modal-close {
  display: none;
} */

.beauty-modal .modal-content .modal-close {
  top: -2px;
  right: 6px;
  font-size: 1.5em;
}

.beauty-modal .modal-body {
  text-align: center;
  padding: 8px 0;
}

.beauty-modal .modal-body p {
  font-size: 0.85rem;
  margin-bottom: 0rem;
}

.beauty-modal .modal-footer {
  border-top: none;
  padding: 0;
}

.beauty-modal .modal-footer .footer-row {
  width: 100%;
}

.beauty-modal .modal-footer .footer-col-btn {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.beauty-modal .modal-footer button {
  position: relative;
  border-radius: 0.25rem;
  border: 1px solid #bf0d3e;
  color: #bf0d3e;
  font-size: 14px;
  padding: 10px 10px;
  min-width: 180px;
  background-color: #fff;
}

.beauty-modal .modal-footer button.btn-modal-primary {
  color: #fff;
  background-color: #bf0d3e;
  margin-bottom: 0.8em;
}

.beauty-modal .modal-footer button.btn-modal-secondary {
  color: #bf0d3e;
  background-color: #fff;
  margin-bottom: 0.8em;
}

.beauty-modal .modal-footer button i {
  font-size: 0.86rem;
  position: absolute;
  top: calc(50% - 0.35rem);
  right: 5px;
}

.beauty-modal .modal-footer .footer-col-advice {
  display: flex;
  flex-direction: row;
  padding: 8px 15px;

  margin-top: 1em;
  border: 1px solid #bf0d3e;
  border-radius: 10px;
}

.beauty-modal .modal-footer .footer-col-advice .icon-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.beauty-modal .modal-footer .footer-col-advice .icon-container img {
  width: 2em;
  height: auto;
}

.beauty-modal .modal-footer .footer-col-advice p {
  text-align: left;
  font-size: 0.7rem;
  line-height: normal;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .modal-dialog.beauty-modal {
    max-width: 650px;
  }
  .beauty-modal .modal-content {
    padding: 20px;
    max-width: 36rem;
    width: 85%;
  }

  .beauty-modal .modal-header h5 {
    font-size: 1.5rem;
  }

  .beauty-modal .modal-body p {
    font-size: 0.95rem;
  }

  .beauty-modal .modal-footer .footer-col-btn {
    flex-direction: row;
    justify-content: space-around;
  }

  .beauty-modal .modal-footer button {
    width: 47%;
    min-width: 200px;
    font-size: 0.8rem;
  }

  .beauty-modal .modal-footer button.btn-modal-primary,
  .beauty-modal .modal-footer button.btn-modal-secondary {
    margin-bottom: 0;
  }

  .beauty-modal .modal-footer .footer-col-advice p {
    font-size: 0.8rem;
  }
}
