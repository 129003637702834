.MuiBox-root .MuiTabs-root.tabs_fondo_libre_graficos {
    position: initial;
    z-index: 2;
    width: 100%;
    background-color: #ffffff;
}

.MuiTabs-flexContainer {
    width: 100%;
    background-color: #ffffff;
}

.tabs_fondo_libre_graficos .MuiTabs-indicator {
    display: block;
    top: initial;
    height: 2px;
    border-radius: 0;
}

.MuiTabs-flexContainer {
    /* padding-top: 6px; */
}

.tabs_fondo_libre_graficos .MuiTabs-flexContainer .MuiButtonBase-root {
    margin: 0;
    padding: 12px 16px;
    font-weight: 700;
}

.tabs_fondo_libre_graficos .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
    border: 0px solid #ce1336;
    border-radius: 0px;
    background: #fff;
    font-weight: 700;
    color: #ce1336;
}

.container-graphic .scrolling-wrapper {
    position: relative;
}

.date_fecha_fin_fondo {
    position: absolute;
    bottom: 82px;
    right: 65px;
    font-size: 11px;
    transform: rotate(-90deg);
    color: rgb(33 37 41 / 96%);
}

.date_fecha_fin_general {
    position: absolute;
    bottom: 83px;
    right: -10px;
    font-size: 11px;
    transform: rotate(-90deg);
    color: rgb(33 37 41 / 96%);
}

.select_ni_custom .MuiInputBase-formControl {
    background-color: #F6F6F6 !important;
    border-radius: 16px !important;
}

.select_ni_custom .MuiSelect-select.MuiSelect-filled.MuiFilledInput-input {
    border-radius: 16px !important;
    background-color: #f6f6f6 !important;
}

.select_ni_custom .MuiInputBase-formControl:before {
    border-bottom: 0px solid !important;
}

.select_ni_custom .MuiInputBase-formControl:after {
    border-bottom: 0px solid !important;
}

.MuiBox-root .MuiTabs-root.tabs_fondo_libre_graficos {
    display: none;
}

@media only screen and (max-width: 600px) {

    .tab_panel_grafico .sc-gsDKAQ.dhBFmi.MuiBox-root,
    .tab_panel_grafico .MuiBox-root.css-19kzrtu {
        padding: 16px 0;
    }

    .tabs_fondo_libre_graficos .MuiTabs-scrollButtons {
        display: inline-flex !important;
    }

    .MuiBox-root .MuiTabs-root.tabs_fondo_libre_graficos {
        display: none;
    }

    .scrolling-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .scrolling-wrapper::-webkit-scrollbar {
        display: block;
        width: 10px;
    }

    .scrolling-wrapper::-webkit-scrollbar-track {
        background: transparent;
    }

    .scrolling-wrapper::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-right: none;
        border-left: none;
    }

    .scrolling-wrapper::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
    }

    .scrolling-wrapper::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
    }

    #generalchartdiv {
        margin: 4px 4px;
        padding: 4px;
    }

    .date_fecha_fin_general {
        bottom: 52px;

    }

    .date_fecha_fin_fondo {
        bottom: 43px;

    }

    .container-graphic p {
        font-weight: bold;
        font-size: 14px;
        margin: 0px !important;
        text-align: center;
    }
}