.dashboard {
  max-width: 1400px;
  width: 100%;
}

/* .dashboard-data {
  margin: 0 3%;
  background-color: #fff;

} */

.dashboard-news_container {
  width: 100%;
  background-color: #f8f9fb;
}

.home-box-padding {
  padding: 20px 20px 0;
}

.home-box-container {
  width: 100%;
  min-width: 650px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.dashboard section .section-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: -0.3px;
}

.bg-light-purple {
  background-color: #2F6D95;
}

.bg-light-green {
  background-color: #24b47e;
}

.bg-light-red {
  background-color: #ce1336;
}

.home-box-header {
  padding: 20px 30px;
}

/* .home-box-body {
  background-color: #fff;
  padding: 20px 30px;
  position: relative;
} */
.home-box-body-desktop {
  background-color: #fff;
  padding: 0px 12px 10px;
  position: relative;
}
.home-box-body h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

.home-box-body button.btn-primary,
.home-box-body a.btn-primary {
  background: #2f6d95;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  padding: 8px 20px;
  border-radius: 7px;
  text-transform: none;
}

.home-box-body button.position-bottom-right,
.home-box-body a.position-bottom-right {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.container-graphic a.position-bottom-right { 
    position: unset;
  /* bottom: -25px; */
  /* right: 30%; */
}

/* .gray-span {
  font-family: Roboto;
  font-style: normal;
  font-size: 0.85rem;
  color: #8e9b9e;
} */

.short-view-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
}

.short-view-container_visual {
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.short-view-container_data {
  flex-grow: 1;
  padding-left: 20px;
}

.short-view-container_data--header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 5px;
}

.short-view-container_data--header h5 {
  font-size: 1.063rem;
  font-weight: 600;
  margin-bottom: 5px;
}
.short-view-container_data p {
  font-size: 0.875rem;
  margin-bottom: 0;
    /* text-transform: capitalize !important; */
}

.short-view-container_data .short-view-container_data--field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-transform: capitalize; */
}

.short-view-container_data .dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.dot.red-dot {
  background-color: #ce1336;
}

.dot.green-dot {
  background-color: #48c180;
}

.short-view-container_data .icon-field {
  width: 12px;
  margin: 0 10px;
}

@media only screen and (min-width: 769px) {
  .dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: auto;
  }
  .home-box-container {
    width: 100%;
    min-width: unset;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  }
  /* .dashboard-data {
    flex-grow: 1;
    background-color: #f8f9fb;
  } */

  .dashboard section .section-title {
    font-size: 1.5rem;
  }

  .dashboard-news_container {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    /* max-width: 500px; */
    /* background-color: #fff; */
  }
}
