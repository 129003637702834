.custom-modal .modal-content {
  max-width: none;
  border-radius: 10px;
}

.custom-modal .modal-header {
  justify-content: center;
  border-bottom: none;
  background-color: #bf0d3e;
}

.custom-modal .modal-header h5 {
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  color: #fff;
}

.custom-modal .modal-header button.close {
  display: none;
}

.custom-modal .modal-content .modal-close {
  top: -6px;
  right: 2px;
  font-size: 1.45em;
}

.custom-modal .modal-body {
  font-size: 0.85rem;
  text-align: center;
}

.custom-modal .modal-body p {
  margin-bottom: 0rem;
  font-size: 1rem;
}

.custom-modal .modal-footer {
  border-top: none;
  padding: 0;
  /* padding-bottom: 0; */
}
.total-buttons {
  background-color: #bf0d3e;
  padding: 20px 0px;
}
.custom-modal .modal-footer .footer-row {
  width: 100%;
}

.remenber-modal {
  border: 1px solid #bf0d3e;
  border-radius: 5px;
  margin-top: 15px;
  padding-top: 10px;
}

.custom-modal .modal-footer .footer-col-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-modal .modal-footer button {
  border-radius: 100px;
  border: 1px solid #bf0d3e;
  color: #bf0d3e;
  font-size: 0.6rem;
  padding: 10px 10px;
  min-width: 180px;
  background-color: #fff;
}

.custom-modal .modal-footer button.btn-modal-primary {
  color: #bf0d3e;
  background-color: #fff;
}

.custom-modal .modal-footer button.btn-modal-secondary {
  color: #bf0d3e;
  background-color: #fff;
}

.custom-modal .modal-footer .footer-col-advice {
  display: flex;
  flex-direction: row;
}

.custom-modal .modal-footer .footer-col-advice .icon-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.custom-modal .modal-footer .footer-col-advice .icon-container img {
  width: 2.5em;
  height: auto;
}

.custom-modal .modal-footer .footer-col-advice p {
  text-align: justify;
  font-size: 14px;
  line-height: normal;
}

@media (min-width: 576px) {
  .modal-dialog.custom-modal {
    max-width: none;
  }

  .custom-modal .modal-content {
    padding: 20px;
    max-width: 35rem;
    width: 85%;
  }

  .custom-modal .modal-content .modal-close {
    top: -2px;
    right: 6px;
    font-size: 1.5em;
  }

  .custom-modal .modal-header h5 {
    font-size: 1.5rem;
  }

  .custom-modal .modal-body {
    margin-bottom: 0;
    font-size: 1.1rem;
    padding: 1rem 0;
  }

  .custom-modal .modal-footer .footer-col-btn {
    flex-direction: row;
    justify-content: space-around;
  }

  .custom-modal .modal-footer button {
    min-height: 40px;
    font-size: 0.7rem;
    padding: 10px;
  }

  .custom-modal .modal-footer .footer-col-advice p {
    font-size: 0.8rem;
  }
}
