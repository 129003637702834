.audio-container-loader {
  display: flex;
  height: 15em;
  justify-content: center;
  align-items: center;
}
.audio-container {
  margin-bottom: 16px;
  width: 100%;
  height: 20em;
  display: flex;
  flex-direction: column;
}
.audio-container .right .top p {
  margin-bottom: 0px;
}

.audio-container .content {
  overflow: hidden;
  flex-grow: 1;
  height: calc(100% - 100px);
}
.audio-container .content .lyrics_pmo {
  padding-bottom: 24px;
  min-height: calc(25em - 100px);
  width: calc(100% - 10px);
  margin: 0 5px;
  text-align: center;
  transition: all 0.25s;
  position: relative;
}
.audio-container .content .lyrics_pmo > div {
  position: relative;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 20px;
  color: #67676782;
  transition: all 0.25s;
}
.audio-container .content .lyrics_pmo > div:before {
  content: attr(note);
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.audio-container .content .lyrics_pmo > div.highlighted {
  color: #000;
  font-size: 0.95rem;
}
.audio-container .player {
  z-index: 10;
  background: rgb(241, 243, 244);
  color: #000;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  box-shadow: 0px -14px 18px 0px rgb(241, 243, 244, 0.9);
}
.audio-container .player .left {
  display: none;
}
.audio-container .player .left img {
  width: 115px;
  height: 100%;
  background-size: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.audio-container .player .right {
  width: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}
.audio-container .player .right .top {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 15px 0;
}
.audio-container .player .right .top .song {
  font-size: 0.95rem;
  font-weight: 600;
}
.audio-container .player .right .top .artist {
  font-size: 0.85rem;
}
.audio-container .player .right .bottom {
  display: flex;
}
.audio-container .player .right .bottom audio {
  flex-grow: 1;
  height: 35px;
}
@media (min-width: 576px) {
  .audio-container {
    height: 25em;
  }

  .audio-container .content .lyrics_pmo > div {
    font-size: 1rem;
    line-height: 25px;
  }

  .audio-container .content .lyrics_pmo > div.highlighted {
    font-size: 1.2rem;
  }

  .audio-container .player .right .top {
    padding-left: 50px;
    padding-right: 50px;
    text-align: left;
  }

  .audio-container .player .left {
    display: block;
  }

  .audio-container .player .right .bottom {
    padding-left: 30px;
    padding-right: 30px;
  }
}
