.espera-estimacion-container {
    width: 100%;
    /* Ocupa todo el ancho del contenedor padre */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    /* Ajusta la distancia del componente al inicio de la página */
}

.espera-estimacion-card {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 24px;
    max-width: 600px;
    width: 100%;
    /* Se adapta al ancho completo en móviles */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.espera-estimacion-header {
    text-align: center;
    margin-bottom: 16px;
}

.espera-estimacion-body {
    text-align: center;
    margin-bottom: 16px;
    margin-bottom: 34px;
}

.espera-estimacion-footer {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
}

.btn-modal-primary {
    background-color: #b71c1c;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 8px 24px;
    /* Reduce la altura */
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    width: 40%;
    /* Ancho ajustado */
    max-width: 300px;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-modal-primary:hover {
    background-color: #a31414;
}

.btn-modal-primary:active {
    background-color: #8d1212;
    /* Color al hacer clic */
}

.btn-modal-primary:disabled {
    background-color: #f0a0a0;
    cursor: not-allowed;
    opacity: 0.6;
    /* Ligera transparencia para botones desactivados */
}

/* Estilos para la lista de notas */
.note-list {
    padding-left: 0.1rem;
    margin: 0;
    list-style: none;
}

.note-item {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 0.3rem;
    line-height: 1.3;
}

.note-link {
    color: #000;
    text-decoration: underline;
    text-underline-offset: 1px;
}

.note-link:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .espera-estimacion-card {
        padding: 16px;
        /* Reduce el padding en pantallas pequeñas */
    }

    .btn-modal-primary {
        font-size: 0.9rem;
        padding: 8px 16px;
        /* Ajusta el tamaño del botón en móviles */
    }

    .note-list {
        padding-left: 1rem;
    }
}