body.m-body__blur>,
body.m-body__blur*:not(.m_chat__menu) {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.m-container {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.m-section {
  position: relative;
  padding: 40px 20px;
}

.m-contact {
  width: 100%;
  max-width: 360px;
  margin: 40px auto;
}

.m-contact__asesor {
  margin-bottom: 40px;
}

.m-contact__asesor h2 {
  color: #196691;
  font-size: 24px;
  margin-bottom: 5px;
}

.m-contact__asesor .m-asesor__item {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  background-color: #fff;
  padding: 5px;
}

.m-contact__asesor .m-asesor__item .m-asesor__image {
  width: 100%;
  max-width: 70px;
  margin-right: 15px;
}

.m-contact__asesor .m-asesor__item .m-asesor__image img {
  width: 100%;
  max-width: 70px;
  border-radius: 50%;
}

.m-contact__asesor .m-asesor__item .m-asesor__content p {
  font-size: 18px;
  line-height: normal;
  color: #196691;
}

.m-contact__personal {
  margin: 20px 0;
  padding: 0 20px;
}

.m-contact__personal .m-personal__item {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
}

.m-contact__personal .m-personal__item .m-personal__image {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  max-width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 5px;
}

.m-contact__personal .m-personal__item .m-personal__image img {
  width: 100%;
  max-width: 35px;
}

.m-contact__personal .m-personal__item .m-personal__image i {
  color: #b9002e;
  font-size: 28px;
}

.m-contact__personal .m-personal__item .m-personal__content {
  width: 100%;
  max-width: max-content;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: 30px;
  border-radius: 25px;
  padding: 0 20px;
  background-color: #fff;
}

.m-contact__personal .m-personal__item .m-personal__content a {
  color: #7b848d;
}

.m-contact__personal .m-personal__item .m-personal__content i {
  color: #7b848d;
  font-size: 12px;
  margin-left: 10px;
}

.m-fixed__contact {
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 35px;
  width: 100%;
  max-width: 250px;
  z-index: 99;
}

.m-fixed__contact .m-image {
  width: 100%;
  max-width: 80px;
  margin-right: 10px;
}

.m-fixed__contact .m-image img {
  width: 100%;
  max-width: 80px;
}

.m-fixed__contact .m-content {
  position: relative;
  margin-top: 0px;
  text-align: center;
  width: 100%;
  max-width: 140px;
  background-color: #b9002e;
  border-radius: 25px;
  padding: 10px 25px;
  right: 15px;
}

.m-fixed__contact .m-content p {
  color: #fff;
  line-height: 16px;
  margin-bottom: 0;
  font-size: 14px;
}

.m-fixed__contact .m-content .m-arrow {
  width: 100%;
  max-width: 25px;
  height: 45px;
  border-radius: 50% 50% 50% 50%/60% 60% 40% 40%;
  background-color: #b9002e;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.m-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  /* display: none; */
}

.zi-0 {
  z-index: 0;
}

.m_chat__menu {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #b9002e;
  width: 100%;
  max-width: 285px;
  color: #fff;
  padding: 25px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translateX(-35px);
  -moz-transform: translateX(-35px);
  -ms-transform: translateX(-35px);
  -o-transform: translateX(-35px);
  transform: translateX(-35px);
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
}

.m_chat__menu.m-visible {
  opacity: 1;
  visibility: visible;
}

.m_chat__menu .m-chat__menu--title {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
}

.m_chat__menu .m-chat__menu--title .m-chat__image {
  width: 100%;
  max-width: 70px;
  border-radius: 50%;
  margin-right: 15px;
}

.m_chat__menu .m-chat__menu--title .m-chat__image img {
  width: 100%;
}

.m_chat__menu .m-chat__menu--title .m-chat__content h2 {
  font-size: 16px;
  margin-bottom: 3px;
  line-height: normal;
}

.m_chat__menu .m-chat__menu--title .m-chat__content p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
}

.m_chat__menu .m-chat__menu--container {
  width: 100%;
}

.m_chat__menu .m-chat__menu--container .m-network__container {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
}

.m_chat__menu .m-chat__menu--container .m-network__container .m-network__item {
  text-align: center;
  width: 100%;
  max-width: 130px;
  margin: 0;
}

.m_chat__menu .m-chat__menu--container .m-network__container .m-network__item p {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 15px 0;
}

.m_chat__menu .m-chat__menu--container .m-network__container .m-network__item .m-image {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  width: 100%;
  max-width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;
}

.m_chat__menu .m-chat__menu--container .m-network__container .m-network__item .m-image img {
  width: 100%;
  max-width: 35px;
}

.m_chat__menu .m-chat__menu--container .m-network__container .m-network__item .m-image i {
  color: #b9002e;
  font-size: 28px;
}

.m_chat__menu .m-chat__menu--container .m-contact__container {
  margin-top: 10px;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.m_chat__menu .m-chat__menu--container .m-contact__container.m-visible {
  height: 75px;
}

.m_chat__menu .m-chat__menu--container .m-contact__container .m-contact__item {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  height: 45px;
  background-color: #fff;
  text-decoration: none;
  margin: 15px 0;
  color: #1a6690;
  font-size: 15px;
  font-weight: 700;
}

.m-close__menu {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  right: -40px;
  visibility: hidden;
  -webkit-transform: translateY(200px) scale(0);
  -moz-transform: translateY(200px) scale(0);
  -ms-transform: translateY(200px) scale(0);
  -o-transform: translateY(200px) scale(0);
  transform: translateY(200px) scale(0);
  -webkit-transition: transform 0.25s ease-in;
  -moz-transition: transform 0.25s ease-in;
  -ms-transition: transform 0.25s ease-in;
  -o-transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in;
  cursor: pointer;
}

.m-close__menu img {
  width: 100%;
  max-width: 90px;
}

.m-visible .m-close__menu {
  visibility: visible;
  -webkit-transform: translateY(0) scale(1);
  -moz-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  -o-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}

@media (max-width: 768px) {
  .mm_content {
    width: 100%;
  }

  .mm_content .mm_logofooter {
    margin: auto 0;
    width: 200px;
  }

  .mm_textfooter h4 {
    font-size: 1.1rem;
    text-align: left;
    margin-left: 10px;
  }

  .mm_textfooter p {
    text-align: left;
    width: 150px;
    margin-left: 10px;
  }

  .m-fixed__contact {
    max-width: 180px;
    bottom: 80px;
    right: 5px;
  }

  .m-fixed__contact .m-image {
    margin-right: 5px;
    max-width: 75px;
  }

  .m-fixed__contact .m-content {
    max-width: 95px;
    padding: 10px 12px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .m-fixed__contact .m-content p {
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
  }

  .m-fixed__contact .m-content .m-arrow {
    height: 40px;
  }

  .m_chat__menu {
    max-width: 300px;
    left: 0;
    margin: 0 auto;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }

  .m-close__menu {
    top: -23px;
    right: -23px;
  }

  .m-close__menu img {
    max-width: 65px;
  }
}