.container-mobile {
}

.container_mobile_icon {
}

.listado_tramite_grid_item {
  padding: 0 0.75rem 0.75rem 0;
}

.block_tepuedeinteresar {
  padding-right: 18px;
}

.grid_container_mobile {
  /* padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px; */
}

.title_breacrumb_home_md {
  padding-left: 1rem;
  color: #2b2b2b;
}

.title_breacrumb_home_xs {
  color: #2b2b2b;
  font-size: 18px;
  font-weight: 400;
  margin-left: 1rem;
  line-height: 24px;
}

.plazosNormativosHeader_desktop {
  padding: 15px 0px;
  background: #f2faff;
  border: 1px solid #b7e3ff;
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.plazosNormativosHeader_mobile {
  display: none;
}

.detail_message_container {
  padding: 8px 8px 0px 8px;
}

.detail_message_container-msg {
  padding: 0 6px 6px 16px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #6e7378;
}

.select_filter_status {
  width: 170px;
}

.select_cuadricula {
  width: 140px;
}

.select_cuadricula_spacer {
  width: 10px;
}

.block_item_plazosnormativos-text {
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-bottom: 0px;
  font-weight: 300;
}

@media screen and (min-width: 576px) {
  .title_breacrumb_home_md {
    display: block;
  }

  .title_breacrumb_home_xs {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .block_item_plazosnormativos {
    background: #ffffff;
    border: 1px solid rgba(20, 20, 20, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 12%);
    border-radius: 24px;
    padding: 12px 16px;
    margin: 12px 16px;
  }

  .block_item_plazosnormativos-text {
    font-size: 14px;
  }

  .select_filter_status {
    width: 140px;
  }

  .select_filter_status .container {
    padding-right: 0px;
  }

  .select_cuadricula,
  .select_cuadricula_spacer {
    display: none;
  }

  .detail_message_container {
    padding: 8px 0px 0px 0px;
  }

  .detail_message_container-msg {
    padding: 0 6px 6px 6px;
  }

  .plazosNormativosHeader_desktop {
    display: none;
  }

  .plazosNormativosHeader_mobile {
    padding: 15px 16px;
    background: #eff3fe;
    border: 0px solid #b7e3ff;
    border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .plazosNormativosHeader_mobile_title {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .plazosNormativosHeader_mobile_title img {
    margin-right: 16px;
  }

  .title_breacrumb_home_md {
    display: none;
  }

  .title_breacrumb_home_xs {
    display: block;
  }

  .container-mobile {
    padding: 0px;
  }

  .container_mobile_icon {
    padding: 0px;
    text-align: center;
  }

  .listado_tramite_grid_item {
    padding: 0 0 1rem 0;
  }

  .grid_container_mobile {
    /* padding-left: 1px;
    padding-right: 2rem;
    overflow-x: hidden; */
  }

  .block_tepuedeinteresar {
    display: none;
  }
}
